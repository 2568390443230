import React from "react";
import logo from '../logo.png';
import { useEffect, useState } from 'react';
import ProductItem from './ProductItem/ProductItem';
import NailExtension from '../assets/nail_extension.jpg';
import hair_color_n_highlight from '../assets/hair_color_n_highlight.jpeg';
import hair_smoothing from '../assets/hair_smoothing.jpeg';
import hair_rebonding from '../assets/hair_rebonding.jpeg';
import nano_plasia_treatment from '../assets/nano_plasia_treatment.jpeg';
import smoothening_n_keratin_treatment from '../assets/smoothening_n_keratin_treatment.png'; 
import { Services } from "../constants/constants";


export default function () {
    const subtitle_words = ['S','k','i','n, ',  'H','a','i','r, ',  'M','a','k','e','u','p, ',  'N','a','i','l','s,',
  'T','a','t','t','o','o','s, ', 'L','a','s','h','e','s ','-',' W','e ','D','o ','I','t ','A','l','l','!'];

  const [wordIndex, setWordIndex] = useState(-1);

  const [subtitle_word, setSubtitleWord] = useState('');

  useEffect(() => {
    if (wordIndex === subtitle_words.length - 1)
      setSubtitleWord(subtitle_words.join(''));
    else if (wordIndex >= 0 && wordIndex < subtitle_words.length)
    setSubtitleWord(subtitle_word + subtitle_words[wordIndex])
  }, [wordIndex]);


  setTimeout(() => {
    if (wordIndex < subtitle_words.length-1)
      setWordIndex((wordIndex+1)%subtitle_words.length);
  }, 100);

  return (
    <div className="App">
      <header className="App-header">
      </header>
      <section className="page-1">
        <div className="page-1-bg"></div>
        <div className="page-1-logo-block">
          <img src={logo} alt="logo" className="page-1-logo" />
          <div className="page-1-brand-title">Antaraa <div className="page-1-brand-subtitle">The Family Salon</div></div>
        </div>

        <div className="page-1-hero-block">
          <div className="page-1-hero-title">
            Your Beauty Haven
          </div>
          <div className="page-1-hero-subtitle">
          {subtitle_word}
          </div>
        </div>

        <a className="page-1-book-button" href="book">Book An Appointment</a>
      </section>

      <section className="page-2">
        <div className="page-2-image">
          <div className="page-2-image-inset"></div>
        </div>
        <div className="page-2-text">
            <div className="page-2-text-title">
              Welcome to Antaraa <br></br>- The Family Salon -<br></br>Your Ultimate Beauty Destination
            </div>
            <div className="page-2-text-subtitle">
              At Welcome to Antaraa- The Family Salon, we believe that beauty is an art form, 
              and our skilled professionals are the artists who can transform your vision of 
              beauty into reality. Nestled in the heart of Guwahati, our parlour is your one-stop 
              destination for all your beauty needs. Step into our world and discover a realm of 
              relaxation, rejuvenation, and radiance.
            </div>
        </div>
      </section>

      <section className="page-3">
        <div className="page-3-header">
          Our Services:
        </div>
        <div className="page-3-products">
          <div className='page-3-product-row'>
            {
              Services && Services.map((service, index) => {
                return <ProductItem text={service.name} description={service.description} key={service.name} img={service.img} />
              })
            }
          </div>
        </div>
      </section>

      <section className="page-4"> 
        <div className='page-4-part page-4-text-section'>
          <div className='page-4-title'>
            What We Promise You
          </div>

          <div className='page-4-promise-item'>
            Non-Toxic Products
          </div>

          <div className='page-4-promise-item'>
            Clean and Safe Environment
          </div>

          <div className='page-4-promise-item'>
            Trained Technicians
          </div>
        </div>
        <div className='page-4-part page-4-img'>
        </div>
      </section>

      <section className="page-5">
        <div className='page-5-title' >
          Our Works:
        </div>
        <div className='page-5-testimonials'>
          <div className="page-5-testimonial">
            <div> 
              Nail Extension
            </div>
            <div>
              <img className="page-5-testimonial-img" src={NailExtension} alt="Nail Extension1" />
            </div>
          </div>

          <div className="page-5-testimonial">
            <div> 
              Hair Color and Highlight
            </div>
            <div>
              <img className="page-5-testimonial-img" src={hair_color_n_highlight} alt="hair color n highlight" />
            </div>
          </div>

          <div className="page-5-testimonial">
            <div> 
              Nano Plasia Treatment
            </div>
            <div>
              <img className="page-5-testimonial-img" src={nano_plasia_treatment} alt="nano plasia treatment" />
            </div>
          </div>

          <div className="page-5-testimonial">
            <div> 
              Hair Smoothing
            </div>
            <div>
              <img className="page-5-testimonial-img" src={hair_smoothing} alt="hair_smoothing" />
            </div>
          </div>

          <div className="page-5-testimonial">
            <div> 
              Smoothening and Keratin Treatment
            </div>
            <div>
              <img className="page-5-testimonial-img" src={smoothening_n_keratin_treatment} alt="smoothening and keratin treatment" />
            </div>
          </div>

          <div className="page-5-testimonial">
            <div> 
              Hair Rebonding
            </div>
            <div>
              <img className="page-5-testimonial-img" src={hair_rebonding} alt="hair rebonding" />
            </div>
          </div>
        </div>

        

        <div className="page-5-footer"></div>
      </section>



      <section className="page-6">
        <div className='page-6-center-block'>
          <div className='page-6-title'>
            First-rate services in
            a cozy and relaxing
            environment
          </div>
          <div className='page-6-subtitle'>
          Join us at Antaraa-The Family Salon and let us help you enhance your beauty, boost your confidence, and bring out the best version of yourself. We can't wait to welcome you to our parlour!
          </div>
        </div>
      </section>

      <section className="page-7">
        <div className='page-7-title'>
        Book Your Appointment <div className='page-7-title-dc'>Today</div>
        </div>
        {/* <div>Ready to experience the transformation? Booking an appointment at Antaraa is easy. You can call us at +91 8822493593 or use our convenient online booking system on our website.</div> */}

        <div className='page-7-row'>
          <div className='page-7-part'>
            <div className='page-7-promise-item'>
              <div className='page-7-promise-text'>Socials</div>
              <div className='page-7-promise-social-icons'>
                <a className='fb' href="https://www.facebook.com/profile.php?id=61551346291724&mibextid=LQQJ4d" />
                <a className='insta' href="https://instagram.com/antaraathefamilysaloon?igshid=MzRlODBiNWFlZA==" />
              </div>
            </div>
            <div className='page-7-promise-item'>
              Email: antaraathefamilysaloon@gmail.com
            </div>
            <div className='page-7-promise-item'>
              Phone: +91 8822493593 &nbsp; +91 8638601692
            </div>
          </div>
          <div className='page-7-part'>
            <div className='page-7-footer'>
              This is an accessible establishment.<br></br>
              Please reach out for additional accommodations.
            </div>
          </div>
          
        </div>
      </section>
    </div>
  );
}