import './App.css';
import StaticPage from './components/StaticPage';
import NoPage from './components/NoPage';
import BookingDashboard from './components/BookingDashboard';
import {BrowserRouter, Routes, Route} from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<StaticPage />} />
        <Route path="book" element={<BookingDashboard />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
