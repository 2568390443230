import React from "react";

export default function ProductItem ( props ) {
    const {img, text, description} = props;

    return (
        <div className="productBox">
            <img className="productImg" src={img} alt={text} />
            <div className="productText">
                {text}
            </div>
            <div className="hide">
                {description}
            </div>
        </div>
    );
}