import hair_care from '../assets/hair_care.png';
import skin_care from '../assets/skin_care.png';
import nail_care from '../assets/nail_care.png';
import makeup from '../assets/makeup.png';
import wax from '../assets/wax.png';
import tattoo from '../assets/tattoo.png';

export const Services = [
    {
        img: hair_care,
        name: "Hair Care",
        description: "Say goodbye to bad hair days. Our expert hairstylists are here to create the perfect hairstyle for you. Whether you're looking for a classic cut, trendy coloring, or extensions for added volume, we've got you covered."
    },
    {
        img: skin_care,
        name: "Skin Care",
        description: "Achieve that flawless complexion you've always dreamed of with our range of skincare treatments. From facials and peels to microdermabrasion, our licensed estheticians will leave your skin feeling refreshed and revitalized."
    },
    {
        img: nail_care,
        name: "Nail Care",
        description: "Pamper your hands and feet with our luxurious nail services. From manicures and pedicures to nail extensions and nail art, our skilled technicians will make your nails look as stunning as you are."
    },
    {
        img: makeup,
        name: "Makeup",
        description: "Whether it's a special occasion, a night out, or just because, our makeup artists will enhance your natural beauty and make you feel like a star. We specialize in bridal makeup too!"
    },
    {
        img: wax,
        name: "Waxing and Threading",
        description: "Get smooth, hair-free skin with our professional waxing and threading services. Our experienced technicians will ensure minimal discomfort and maximum results."
    },
    {
        img: tattoo,
        name: "Tattoo",
        description: "Our experienced Team will give you the best ensured."
    }
];